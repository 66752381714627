define("shared/version-choices/service", ["exports", "semver", "shared/utils/parse-version", "shared/utils/constants"], function (_exports, _semver, _parseVersion, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    intl: Ember.inject.service(),
    settings: Ember.inject.service(),
    defaultK8sVersionRange: Ember.computed.alias(`settings.${_constants.default.SETTING.VERSION_SYSTEM_K8S_DEFAULT_RANGE}`),

    parseCloudProviderVersionChoices(versions, providerVersion, mode, maxVersionRange = null, includePrerelease = false, experimentalRange = null) {
      let {
        intl,
        defaultK8sVersionRange
      } = this;
      maxVersionRange = maxVersionRange ? maxVersionRange : defaultK8sVersionRange.split(' ').pop();
      return versions.map(version => {
        if ((0, _parseVersion.satisfies)((0, _parseVersion.coerceVersion)(version), maxVersionRange, {
          includePrerelease
        })) {
          const experimental = experimentalRange && (0, _parseVersion.satisfies)((0, _parseVersion.coerceVersion)(version), experimentalRange) ? intl.t('generic.experimental') : '';
          const out = {
            label: `${version} ${experimental}`,
            value: version
          };

          if (mode === 'edit') {
            if (_semver.default.lt((0, _parseVersion.coerceVersion)(version), (0, _parseVersion.coerceVersion)(providerVersion))) {
              Ember.setProperties(out, {
                disabled: true,
                label: `${out.label} ${intl.t('formVersions.downgrade')}`
              });
            }
          }

          return out;
        }
      }).filter(version => version);
    },

    parseCloudProviderVersionChoicesV2(versions, providerVersion, mode, maxVersionRange = null, includePrerelease = false) {
      let {
        intl,
        defaultK8sVersionRange
      } = this;
      maxVersionRange = maxVersionRange ? maxVersionRange : defaultK8sVersionRange.split(' ').pop();
      return versions.map(version => {
        if ((0, _parseVersion.satisfies)(version, maxVersionRange, {
          includePrerelease
        })) {
          const out = {
            label: version,
            value: version
          };

          if (mode === 'edit') {
            if (_semver.default.lt(version, providerVersion, {
              includePrerelease
            })) {
              Ember.setProperties(out, {
                disabled: true,
                label: `${out.label} ${intl.t('formVersions.downgrade')}`
              });
            }
          }

          return out;
        }
      }).filter(version => version);
    }

  });

  _exports.default = _default;
});